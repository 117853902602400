import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Grid,
  Card,
  CardContent,
  Avatar,
  CardHeader,
  // Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Radio,
  RadioGroup,
  FormControlLabel,
  Tooltip,
  Snackbar,
  Alert,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import apiHelper from "../helper/apiHelper";
import endpoints from "../config/apiConfig";
import {
  format,
  startOfWeek,
  addDays,
  isSameDay,
  isBefore,
  addWeeks,
  subWeeks,
} from "date-fns";
import { colorMap } from "../config/constant";
import { utcToZonedTime } from "date-fns-tz";
import { localeMap, timezone } from "../config/types";
import { useTranslation } from "react-i18next";
import { enUS } from "date-fns/locale";

interface Meal {
  id: string;
  name: string;
  imageUrl: string;
}

interface MealScheduleDetail {
  mealId: string;
  count: number;
  mealType: "BREAKFAST" | "LUNCH" | "DINNER";
}

interface DailyMealSchedule {
  date: string;
  breakfastMeals: MealScheduleDetail[];
  lunchMeals: MealScheduleDetail[];
  dinnerMeals: MealScheduleDetail[];
  blocked?: boolean;
}

interface FetchMealScheduleResponse {
  vendorId: string;
  schedules: DailyMealSchedule[];
}

const MealScheduleOverviewPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const locale =
    localeMap[i18n.language as "en" | "zh-Hans" | "zh-Hant"] || enUS;
  const vendorId = localStorage.getItem("vendorId") || "";
  const [meals, setMeals] = useState<Meal[]>([]);
  const [mealSchedules, setMealSchedules] = useState<DailyMealSchedule[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [pauseBusiness, setPauseBusiness] = useState<boolean>(false);
  const [availabilityDialogOpen, setAvailabilityDialogOpen] =
    useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [availability, setAvailability] = useState<string>("open");
  const today = utcToZonedTime(new Date(), timezone);
  const [currentWeek, setCurrentWeek] = useState<Date>(
    startOfWeek(today, { weekStartsOn: 0 }),
  );
  const startDate = format(currentWeek, "yyyy-MM-dd");
  const endDate = format(addDays(currentWeek, 27), "yyyy-MM-dd");
  const [snackbar, setSnackbar] = useState<{
    severity: "success" | "error";
    message: string;
  } | null>(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchMeals = async () => {
      try {
        if (vendorId) {
          const data = await apiHelper(endpoints.fetchMeals + vendorId, {
            method: "GET",
          });
          setMeals(data);
        }
      } catch (error) {
        console.error("Failed to fetch meals:", error);
      }
    };

    fetchMeals();
  }, [vendorId]);

  useEffect(() => {
    const fetchMealSchedules = async () => {
      setLoading(true);
      try {
        if (vendorId) {
          const request = { vendorId, startDate, endDate };
          const data: FetchMealScheduleResponse = await apiHelper(
            endpoints.getMealScheduleOverview,
            {
              method: "POST",
              body: request,
            },
          );
          setMealSchedules(data.schedules);
        }
      } catch (error) {
        console.error("Failed to fetch meal schedules:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMealSchedules();
  }, [startDate, endDate, vendorId]);

  const handleConfirmToggle = (confirmed: boolean) => {
    if (confirmed) {
      setPauseBusiness(true);
    } else {
      setPauseBusiness(false);
    }
    setConfirmDialogOpen(false);
  };

  const handleDayClick = (day: Date) => {
    setSelectedDate(day);
    const daySchedule = mealSchedules.find(
      (schedule) => schedule.date === format(day, "yyyy-MM-dd"),
    );
    setAvailability(daySchedule?.blocked ? "blocked" : "open");
    setAvailabilityDialogOpen(true);
  };

  const handleSaveAvailability = async () => {
    if (selectedDate) {
      const dateStr = format(selectedDate, "yyyy-MM-dd");
      const updatedSchedules = mealSchedules.map((schedule) =>
        schedule.date === dateStr
          ? { ...schedule, blocked: availability === "blocked" }
          : schedule,
      );
      setMealSchedules(updatedSchedules);

      try {
        const endpoint =
          availability === "blocked"
            ? endpoints.blockDate
            : endpoints.unblockDate;
        await apiHelper(endpoint, {
          method: "POST",
          body: { vendorId, date: selectedDate.toISOString() },
        });
        setSnackbar({
          severity: "success",
          message: t(`mealScheduleOverviewPage.${availability}SuccessMessage`),
        });

        setAvailabilityDialogOpen(false);
      } catch (error) {
        console.error("Failed to update date availability:", error);
        setSnackbar({
          severity: "error",
          message: t("mealScheduleOverviewPage.updateAvailabilityError"),
        });
      }
    }
  };

  const renderMealSummary = (
    mealSchedule: MealScheduleDetail[],
    type: "BREAKFAST" | "LUNCH" | "DINNER",
    isBlocked: boolean | undefined,
  ) => {
    if (mealSchedule.length && !isBlocked) {
      return mealSchedule.map((meal) => (
        <Box
          key={meal.mealId}
          display="flex"
          alignItems="center"
          sx={{ mb: 1, backgroundColor: colorMap[type], borderRadius: 1, p: 1 }}
        >
          <Avatar
            src={
              endpoints.mealImagePrefix +
              meals.find((m) => m.id === meal.mealId)?.imageUrl
            }
            alt={meals.find((m) => m.id === meal.mealId)?.name}
            sx={{ width: 24, height: 24, mr: 1 }}
          />
          <Typography variant="body2">
            {meals.find((m) => m.id === meal.mealId)?.name}: {meal.count}
          </Typography>
        </Box>
      ));
    } else {
      return (
        <Typography variant="body2">
          {t("mealScheduleOverviewPage.noMealsScheduled")}
        </Typography>
      );
    }
  };

  const renderDaySlot = (day: Date) => {
    const daySchedule = mealSchedules.find(
      (schedule) => schedule.date === format(day, "yyyy-MM-dd"),
    );

    const isBlocked = daySchedule?.blocked;
    const hasSchedule =
      daySchedule?.breakfastMeals ||
      daySchedule?.lunchMeals ||
      daySchedule?.dinnerMeals;

    const isEditable = !isBefore(
      day,
      addDays(startOfWeek(today, { weekStartsOn: 0 }), 14),
    );

    return (
      <Tooltip
        title={isEditable ? "" : t("mealScheduleOverviewPage.editDateDisabled")}
      >
        <Card
          sx={{
            borderRadius: 0,
            mb: 1,
            height: "100%",
            cursor: isEditable ? "pointer" : "not-allowed",
            "&:hover": {
              backgroundColor: isEditable ? "#f0f0f0" : "inherit",
            },
            border: isSameDay(day, today) ? "3px solid orange" : "none",
            backgroundColor: isBlocked ? "#ffebee" : "inherit",
            backgroundImage: isBlocked
              ? "repeating-linear-gradient(45deg, #f0f0f0, #f0f0f0 10px, #ffffff 10px, #ffffff 20px)"
              : "none",
            opacity: isEditable ? 1 : 0.5,
          }}
          onClick={() => {
            if (isEditable) handleDayClick(day);
          }}
        >
          <CardHeader
            title={format(day, "EEE, MMM d", { locale })}
            sx={{
              backgroundColor: "#f0f0f0",
              textAlign: "center",
              p: 1,
            }}
          />
          <CardContent>
            {hasSchedule ? (
              <>
                <Typography
                  variant="body2"
                  sx={{ mt: 1, mb: 1, fontWeight: "bold" }}
                >
                  {t("mealType.breakfast")}
                </Typography>
                {renderMealSummary(
                  daySchedule.breakfastMeals,
                  "BREAKFAST",
                  isBlocked,
                )}
                <Typography
                  variant="body2"
                  sx={{ mt: 1, mb: 1, fontWeight: "bold" }}
                >
                  {t("mealType.lunch")}
                </Typography>
                {renderMealSummary(daySchedule.lunchMeals, "LUNCH", isBlocked)}
                <Typography
                  variant="body2"
                  sx={{ mt: 1, mb: 1, fontWeight: "bold" }}
                >
                  {t("mealType.dinner")}
                </Typography>
                {renderMealSummary(
                  daySchedule.dinnerMeals,
                  "DINNER",
                  isBlocked,
                )}
              </>
            ) : (
              <Typography variant="body2" sx={{ mt: 1 }}>
                {t("mealScheduleOverviewPage.noMealsScheduled")}
              </Typography>
            )}
          </CardContent>
        </Card>
      </Tooltip>
    );
  };

  const handleNextWeek = () => {
    setCurrentWeek((prev) => addWeeks(prev, 4));
  };

  const handlePrevWeek = () => {
    setCurrentWeek((prev) => subWeeks(prev, 4));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        sx={{
          maxWidth: isMobile ? "90vw" : "auto", // Ensure container width adapts to mobile
        }}
      >
        <Box
          sx={{
            transform: isMobile ? "scale(0.55)" : "none", // Scale content on mobile
            transformOrigin: "top left",
            width: isMobile ? "200%" : "100%", // Counteract the scale effect for width
          }}
        >
          <Typography component="h1" variant="h5" sx={{ mb: 2, ml: 1 }}>
            {t("mealScheduleOverviewPage.title")}
          </Typography>
          <Box justifyContent="space-between" alignItems="center">
            <Button
              size="large"
              onClick={handlePrevWeek}
              disabled={isBefore(currentWeek, today)}
            >
              {t("mealScheduleOverviewPage.previous4Weeks")}
            </Button>
            <Button size="large" onClick={handleNextWeek}>
              {t("mealScheduleOverviewPage.next4Weeks")}
            </Button>
          </Box>
          {/* <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mt: 1,
          alignItems: "center",
        }}
      >
        <Typography>{t("mealScheduleOverviewPage.pauseBusiness")}</Typography>
        <Switch
          checked={pauseBusiness}
          onChange={() => setConfirmDialogOpen(true)}
        />
      </Box> */}
          {!loading ? (
            <Grid
              container
              spacing={0}
              sx={{ minWidth: isMobile ? 450 : 1050, mt: 4 }}
            >
              {Array.from({ length: 28 }).map((_, index) => {
                const day = addDays(currentWeek, index);
                return (
                  <Grid item xs={12 / 7} key={index} sx={{ minWidth: 150 }}>
                    {renderDaySlot(day)}
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <CircularProgress sx={{ m: 1, p: 1 }} />
          )}
          <Dialog
            open={confirmDialogOpen}
            onClose={() => setConfirmDialogOpen(false)}
            fullWidth
          >
            <DialogTitle>
              {t("mealScheduleOverviewPage.confirmAction")}
            </DialogTitle>
            <DialogContent>
              <Typography>
                {t("mealScheduleOverviewPage.confirmMessage", {
                  action: pauseBusiness
                    ? t("mealScheduleOverviewPage.resume")
                    : t("mealScheduleOverviewPage.pause"),
                  autoPopulating: pauseBusiness
                    ? t("mealScheduleOverviewPage.stopAutoPopulating")
                    : t("mealScheduleOverviewPage.resumeAutoPopulating"),
                })}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setConfirmDialogOpen(false)}
                color="primary"
              >
                {t("mealScheduleOverviewPage.cancel")}
              </Button>
              <Button
                onClick={() => handleConfirmToggle(!pauseBusiness)}
                color="primary"
              >
                {t("mealScheduleOverviewPage.confirm")}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={availabilityDialogOpen}
            onClose={() => setAvailabilityDialogOpen(false)}
            fullWidth
          >
            <DialogTitle>
              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                {t("mealScheduleOverviewPage.availability")}
              </Typography>
            </DialogTitle>
            <DialogContent dividers>
              <Typography>
                {t("mealScheduleOverviewPage.setAvailabilityFor", {
                  date: selectedDate
                    ? format(selectedDate, "EEE, MMM d", { locale })
                    : "",
                })}
              </Typography>
              <RadioGroup
                value={availability}
                onChange={(e) => setAvailability(e.target.value)}
                sx={{ mt: 2 }}
              >
                <FormControlLabel
                  value="open"
                  control={<Radio />}
                  label={t("mealScheduleOverviewPage.open")}
                />
                <FormControlLabel
                  value="blocked"
                  control={<Radio />}
                  label={t("mealScheduleOverviewPage.blocked")}
                />
              </RadioGroup>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setAvailabilityDialogOpen(false)}
                color="primary"
              >
                {t("mealScheduleOverviewPage.cancel")}
              </Button>
              <Button onClick={handleSaveAvailability} color="primary">
                {t("mealScheduleOverviewPage.save")}
              </Button>
            </DialogActions>
          </Dialog>
          <Snackbar
            open={!!snackbar}
            autoHideDuration={3000}
            onClose={() => setSnackbar(null)}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={() => setSnackbar(null)}
              severity={snackbar?.severity}
            >
              {snackbar?.message}
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default MealScheduleOverviewPage;
