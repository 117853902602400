import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Paper,
  LinearProgress,
  TablePagination,
  Snackbar,
  Alert,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import apiHelper from "../helper/apiHelper";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ORDER_SERVICE_URL, s3 } from "../config/apiConfig";

const S3_BUCKET_NAME = "vendor-biweekly-report";

interface S3File {
  fileName: string;
  createDate: Date;
}

const PayoutReport = () => {
  const vendorId = localStorage.getItem("vendorId") || "";
  const vendorName = localStorage.getItem("vendorName") || "";
  const navigate = useNavigate();
  const [files, setFiles] = useState<S3File[]>([]);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { t } = useTranslation();
  const [snackbar, setSnackbar] = useState<{
    severity: "success" | "error";
    message: string;
  } | null>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const fetchFiles = useCallback(async () => {
    setLoading(true);
    try {
      const params = { Bucket: S3_BUCKET_NAME, Prefix: `${vendorId}/` };
      const result = await s3.listObjectsV2(params).promise();
      const fileList: S3File[] =
        result.Contents?.map((file) => ({
          fileName: file.Key || "",
          createDate: file.LastModified || new Date(),
        })) || [];

      const sortedFiles = fileList.sort(
        (a, b) =>
          new Date(b.createDate).getTime() - new Date(a.createDate).getTime(),
      );

      setFiles(sortedFiles);
    } catch (error) {
      console.error("Error fetching files:", error);
    } finally {
      setLoading(false);
    }
  }, [vendorId]);

  useEffect(() => {
    fetchFiles();
  }, [fetchFiles]);

  const formatDate = (date: Date) => {
    return date.toLocaleString("en-CA", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
  };

  const handleStartDateChange = (newDate: Date | null) => {
    setStartDate(newDate);
  };

  const handleEndDateChange = (newDate: Date | null) => {
    setEndDate(newDate);
  };

  const handleClose = () => {
    setModalOpen(false);
    setStartDate(new Date());
    setEndDate(new Date());
  };

  const downloadFile = async (fileName: string) => {
    const params = {
      Bucket: S3_BUCKET_NAME,
      Key: fileName,
    };
    const url = s3.getSignedUrl("getObject", params);
    window.open(url, "_blank");
  };

  const formatDateForApi = (date: Date | null): string => {
    if (!date) return "";
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const generatePayoutReport = async (shouldDownload: boolean) => {
    try {
      setModalLoading(true);
      const formattedStartDate = formatDateForApi(startDate);
      const formattedEndDate = formatDateForApi(endDate);
      const result = await apiHelper(
        `${ORDER_SERVICE_URL}/vendor/orders/vendor-excel-report?vendorId=${vendorId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`,
        {
          method: "GET",
        },
      );
      handleClose();

      if (shouldDownload && result.keyName) {
        const params = {
          Bucket: S3_BUCKET_NAME,
          Key: result.keyName,
        };
        const url = s3.getSignedUrl("getObject", params);
        window.open(url, "_blank");
      }

      fetchFiles();
      setSnackbar({
        severity: "success",
        message: t(`payoutReport.report_generated_successfully`),
      });
    } catch (error) {
      console.error("Error generating payout report:", error);
      setSnackbar({
        severity: "error",
        message: t("payoutReport.report_generation_failed"),
      });
    } finally {
      setModalLoading(false);
    }
  };

  const generateAndDownloadPayoutReport = async () => {
    await generatePayoutReport(true);
  };

  const generatePayoutReportOnly = async () => {
    await generatePayoutReport(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box
      sx={{
        p: 3,
        maxWidth: isMobile ? "90vw" : "auto", // Ensure container width adapts to mobile
      }}
    >
      <Box
        sx={{
          transform: isMobile ? "scale(0.8)" : "none", // Scale content on mobile
          transformOrigin: "top left",
          width: isMobile ? "130%" : "100%", // Counteract the scale effect for width
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Typography variant="h6">
            {t("payoutReport.payout_report")}
          </Typography>
          <Box>
            <Button
              variant="contained"
              color="inherit"
              onClick={() => navigate(-1)}
              sx={{ mr: 1 }}
            >
              {t("payoutReport.back")}
            </Button>
            <Button variant="contained" onClick={() => setModalOpen(true)}>
              {t("payoutReport.generate_payout_report")}
            </Button>
          </Box>
        </Box>

        {loading ? (
          <CircularProgress />
        ) : (
          <TableContainer component={Paper} aria-hidden={modalOpen}>
            <Table>
              <TableHead sx={{ backgroundColor: "#f5f5f5" }}>
                <TableRow>
                  <TableCell>{t("payoutReport.file_name")}</TableCell>
                  <TableCell>{t("payoutReport.create_date")}</TableCell>
                  <TableCell>{t("payoutReport.actions")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {files
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((file) => (
                    <TableRow key={file.fileName}>
                      <TableCell>
                        {file.fileName.split("/")[1] ?? file.fileName}
                      </TableCell>
                      <TableCell>
                        {formatDate(new Date(file.createDate))}
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          onClick={() => downloadFile(file.fileName)}
                        >
                          {t("payoutReport.download")}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={files.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={t("payoutReport.rows_per_page")}
              labelDisplayedRows={({ from, to, count }) =>
                `${from} - ${to} ${t("payoutReport.of")} ${
                  count !== -1
                    ? `${count} ${t("payoutReport.results")}`
                    : `more than ${to}`
                }`
              }
            />
          </TableContainer>
        )}

        {/* Modal for generating payout report */}
        <Dialog open={modalOpen} onClose={handleClose} maxWidth="sm" fullWidth>
          <DialogTitle>{t("payoutReport.generate_payout_report")}</DialogTitle>
          <DialogContent>
            {modalLoading && <LinearProgress sx={{ mb: 2 }} />}
            <TextField
              disabled
              label={t("payoutReport.vendor_name")}
              value={vendorName}
              fullWidth
              margin="dense"
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 2,
                }}
              >
                <DatePicker
                  label={t("payoutReport.start_date")}
                  value={startDate}
                  onChange={(date) => handleStartDateChange(date)}
                  maxDate={endDate ? endDate : new Date()}
                  sx={{ width: "50%", mr: 1 }}
                />
                <DatePicker
                  label={t("payoutReport.end_date")}
                  value={endDate}
                  onChange={(date) => handleEndDateChange(date)}
                  minDate={startDate ? startDate : new Date()}
                  sx={{ width: "50%", ml: 1 }}
                />
              </Box>
            </LocalizationProvider>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "space-between", px: 3, pb: 2 }}>
            <Button onClick={handleClose}>{t("payoutReport.cancel")}</Button>
            <Box>
              <Button
                onClick={generatePayoutReportOnly}
                variant="contained"
                sx={{ mr: 1 }}
              >
                {t("payoutReport.generate")}
              </Button>
              <Button
                onClick={generateAndDownloadPayoutReport}
                variant="contained"
              >
                {t("payoutReport.generate_and_download")}
              </Button>
            </Box>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={!!snackbar}
          autoHideDuration={3000}
          onClose={() => setSnackbar(null)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={() => setSnackbar(null)}
            severity={snackbar?.severity}
          >
            {snackbar?.message}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default PayoutReport;
