import { enUS, zhCN, zhHK } from "date-fns/locale";

export interface RouteItem {
  label: string;
  icon: React.ReactNode;
  link: string;
}

export interface MealTime {
  from: string;
  to: string;
  enabled: boolean;
}

export interface DayOperation {
  enabled: boolean;
  meals: {
    [key: string]: MealTime;
  };
}

export interface OperationHours {
  [key: string]: DayOperation;
}

export interface Price {
  currency: string;
  originalPrice: number;
  customerPrice: number | null;
  vendorPrice: number;
}

export interface Meal {
  id?: string;
  name: string;
  vendorId: string;
  description: string;
  ingredients: string;
  imageUrl: string;
  price: Price;
  breakfast?: boolean;
  lunch?: boolean;
  dinner?: boolean;
  drinkOptionGroup?: {
    isRequired: boolean;
    options: { name: string; priceAdjustment: string }[];
  };
  active: boolean;
  available: boolean;
}

export interface MealScheduleDetail {
  mealId: string;
  count: number;
  mealType: "BREAKFAST" | "LUNCH" | "DINNER";
}

export interface FetchMealScheduleResponse {
  vendorId: string;
  weekStartDate: string;
  breakfastMeals: Record<string, MealScheduleDetail[]>;
  lunchMeals: Record<string, MealScheduleDetail[]>;
  dinnerMeals: Record<string, MealScheduleDetail[]>;
}

export interface MealSchedulePresetResponse {
  presetName: string;
  breakfastMeals: Record<string, MealScheduleDetail[]>;
  lunchMeals: Record<string, MealScheduleDetail[]>;
  dinnerMeals: Record<string, MealScheduleDetail[]>;
}

export interface OrderResponse {
  orderTime: string;
  orderId: string;
  userId: string;
  vendorId: string;
  mealId: string;
  mealType: string;
  mealCustomerPrice: number;
  mealOriginalPrice: number;
  mealVendorPrice: number;
  pickupTime: string;
  pickupCode: string;
  status: string;
  imageUrl: string;
  mealName: string;
  vendorName: string;
  vendorAddress: string;
  mealDescription: string;
  latitude: number;
  longitude: number;
}

export const timezone = "Asia/Hong_Kong";

export const localeMap = {
  en: enUS,
  "zh-Hans": zhCN,
  "zh-Hant": zhHK,
};
