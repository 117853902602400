import AWS from "aws-sdk";

export const API_GATEWAY_URL =
  "https://8195i780va.execute-api.ap-northeast-1.amazonaws.com/prod/api/v1";
// "https://7mbc6zo92f.execute-api.ap-northeast-1.amazonaws.com/beta/api/v1";

export const VENDOR_SERVICE_URL = API_GATEWAY_URL;
export const ORDER_SERVICE_URL = API_GATEWAY_URL;

// export const VENDOR_SERVICE_URL = "http://192.168.1.223:8084/api/v1";
// export const ORDER_SERVICE_URL = "http://192.168.1.110:8085/api/v1";

export const GOOGLE_PLACE_API_KEY = "AIzaSyD1ZRmudvGS24bBy8w-kM9lqVBmGug2bSM";

export const endpoints = {
  mealImagePrefix:
    "https://grubgo-partner-images4f1a7-tokyo.s3.ap-northeast-1.amazonaws.com/public/",
  fetchMeals: `${VENDOR_SERVICE_URL}/vendors/fetchMeals/`,
  addMeal: `${VENDOR_SERVICE_URL}/vendors/addMeal`,
  editMeal: `${VENDOR_SERVICE_URL}/vendors/editMeal`,
  disableMeal: `${VENDOR_SERVICE_URL}/vendors/disableMeal/`,
  setOperationHours: `${VENDOR_SERVICE_URL}/vendors/setOperationHours`,
  getOperationHours: `${VENDOR_SERVICE_URL}/vendors/getOperationHours/`,
  saveMealSchedule: `${VENDOR_SERVICE_URL}/vendor/meals/saveMealSchedule`,
  getMealSchedule: `${VENDOR_SERVICE_URL}/vendor/meals/getMealSchedule`,
  getMealScheduleOverview: `${VENDOR_SERVICE_URL}/vendor/meals/getMealScheduleOverview`,
  saveAsMealSchedulePreset: `${VENDOR_SERVICE_URL}/vendor/meals/saveAsMealSchedulePreset`,
  addSchedulePreset: `${VENDOR_SERVICE_URL}/vendor/meals/addSchedulePreset`,
  saveSchedulePreset: `${VENDOR_SERVICE_URL}/vendor/meals/saveSchedulePreset/`,
  getPresetSummaries: `${VENDOR_SERVICE_URL}/vendor/meals/presetSummaries/`,
  getPresetDetail: `${VENDOR_SERVICE_URL}/vendor/meals/preset/`,
  deletePreset: `${VENDOR_SERVICE_URL}/vendor/meals/deleteSchedulePreset/`,
  addVendor: `${VENDOR_SERVICE_URL}/vendors/add`,
  getDetailsByPlaceId: `${VENDOR_SERVICE_URL}/vendors/getDetailsByPlaceId`,
  confirmStatus: `${VENDOR_SERVICE_URL}/vendors/confirmStatus`,
  vendorSettings: `${VENDOR_SERVICE_URL}/vendor/meals/settings/`,
  unblockDate: `${VENDOR_SERVICE_URL}/vendor/meals/unblockDate`,
  blockDate: `${VENDOR_SERVICE_URL}/vendor/meals/blockDate`,
  getVendorInfo: `${VENDOR_SERVICE_URL}/vendors/info`,
  updateVendorInfo: `${VENDOR_SERVICE_URL}/vendors/updateVendorInfo`,
  getOrdersByDate: `${ORDER_SERVICE_URL}/vendor/orders/getOrdersByDate/`,
  getOrdersFromDateRange: `${ORDER_SERVICE_URL}/vendor/orders/getOrdersFromDateRange/`,
};
export const s3 = new AWS.S3({
  region: "ap-northeast-1",
  accessKeyId: "AKIAVRUVVQVRPIJKLDXN",
  secretAccessKey: "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",
});
export default endpoints;
