import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../redux/auth/store";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp";
import KeyboardArrowUpSharpIcon from "@mui/icons-material/KeyboardArrowUpSharp";
import UserMenu from "./UserMenu";
import LanguageSelector from "./LanguageSelector";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import yumgoIcon from "../../assets/images/yumgo-full.jpg";
import colors from "../../config/colors";

interface TopBarProps {
  onToggleSidebar?: () => void;
}

export default function TopBar({ onToggleSidebar }: TopBarProps) {
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated,
  );
  const user = useSelector((state: RootState) => state.auth.user);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const userIcon = anchorEl ? (
    <KeyboardArrowUpSharpIcon sx={{ cursor: "pointer" }} />
  ) : (
    <KeyboardArrowDownSharpIcon sx={{ cursor: "pointer" }} />
  );

  const navigate = useNavigate();
  const handleNavigateHome = () => {
    navigate("/");
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: "white",
      }}
    >
      <Toolbar>
        {isAuthenticated && (
          <IconButton
            color="inherit"
            edge="start"
            onClick={onToggleSidebar}
            sx={{ mr: 1, display: { sm: "none" }, color: colors.primary }}
          >
            <MenuIcon />
          </IconButton>
        )}
        <img
          src={yumgoIcon}
          alt="YumGo Icon"
          style={{ height: 45, marginRight: 8, cursor: "pointer" }}
          onClick={handleNavigateHome}
        />
        <Box sx={{ flexGrow: 1 }} />
        <LanguageSelector />
        {isAuthenticated && user && (
          <>
            <div
              onClick={handleOpenMenu}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                color: colors.primary,
              }}
            >
              <AccountCircleRoundedIcon sx={{ mr: 1 }} fontSize="large" />
              {!isSmallScreen && (
                <Typography
                  sx={{
                    mr: 1,
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "Poppins",
                    fontWeight: 600,
                  }}
                >
                  {localStorage.getItem("vendorName") ?? "Admin"}
                </Typography>
              )}
              {userIcon}
            </div>
            <UserMenu anchorEl={anchorEl} onClose={handleCloseMenu} />
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}
